// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/user/store/reducer'
import assets from '@src/views/asset/store/reducer'
import transfers from '@src/views/transfers/store/reducer'
import usertransfers from '@src/views/user/transfers/store/reducer'
import ecommerce from '@src/views/ecommerce/store/reducer'
import history from '@src/views/history/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  assets,
  transfers,
  usertransfers,
  ecommerce,
  history
})

export default rootReducer
